@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=9b65a6c1-8ccf-4988-91e3-e9cb023425c4");

@font-face{
	font-family:"AvenirNextW01-Light";
	src:url("../fonts/AvenirNextW01-Light/avenir-next-w01-light.eot?#iefix");
	src:url("../fonts/AvenirNextW01-Light/avenir-next-w01-light.eot?#iefix") format("eot"),
		url("../fonts/AvenirNextW01-Light/avenir-next-w01-light.woff2") format("woff2"),
		url("../fonts/AvenirNextW01-Light/avenir-next-w01-light.woff") format("woff"),
		url("../fonts/AvenirNextW01-Light/avenir-next-w01-light.ttf") format("truetype"),
		url("../fonts/AvenirNextW01-Light/avenir-next-w01-light.svg#avenir-next-w01-light") format("svg");
}
@font-face{
	font-family:"AvenirNextW01-LightItalic";
	src:url("../fonts/AvenirNextW01-LightItalic/avenir-next-w01-light-italic.eot?#iefix");
	src:url("../fonts/AvenirNextW01-LightItalic/avenir-next-w01-light-italic.eot?#iefix") format("eot"),
		url("../fonts/AvenirNextW01-LightItalic/avenir-next-w01-light-italic.woff2") format("woff2"),
		url("../fonts/AvenirNextW01-LightItalic/avenir-next-w01-light-italic.woff") format("woff"),
		url("../fonts/AvenirNextW01-LightItalic/avenir-next-w01-light-italic.ttf") format("truetype"),
		url("../fonts/AvenirNextW01-LightItalic/avenir-next-w01-light-italic.svg#avenir-next-w01-light-italic") format("svg");
}
@font-face{
	font-family:"AvenirNextLTW01-Regular";
	src:url("../fonts/AvenirNextLTW01-Regular/avenir-next-lt-w01-regular.eot?#iefix");
	src:url("../fonts/AvenirNextLTW01-Regular/avenir-next-lt-w01-regular.eot?#iefix") format("eot"),
		url("../fonts/AvenirNextLTW01-Regular/avenir-next-lt-w01-regular.woff2") format("woff2"),
		url("../fonts/AvenirNextLTW01-Regular/avenir-next-lt-w01-regular.woff") format("woff"),
		url("../fonts/AvenirNextLTW01-Regular/avenir-next-lt-w01-regular.ttf") format("truetype"),
		url("../fonts/AvenirNextLTW01-Regular/avenir-next-lt-w01-regular.svg#avenir-next-lt-w01-regular") format("svg");
}
@font-face{
	font-family:"AvenirNextLTW01-Italic";
	src:url("../fonts/AvenirNextLTW01-Italic/avenir-next-lt-w01-italic.eot?#iefix");
	src:url("../fonts/AvenirNextLTW01-Italic/avenir-next-lt-w01-italic.eot?#iefix") format("eot"),
		url("../fonts/AvenirNextLTW01-Italic/avenir-next-lt-w01-italic.woff2") format("woff2"),
		url("../fonts/AvenirNextLTW01-Italic/avenir-next-lt-w01-italic.woff") format("woff"),
		url("../fonts/AvenirNextLTW01-Italic/avenir-next-lt-w01-italic.ttf") format("truetype"),
		url("../fonts/AvenirNextLTW01-Italic/avenir-next-lt-w01-italic.svg#avenir-next-lt-w01-italic") format("svg");
}
@font-face{
	font-family:"AvenirNextLTW01-Medium";
	src:url("../fonts/AvenirNextLTW01-Medium/avenir-next-lt-w01-medium.eot?#iefix");
	src:url("../fonts/AvenirNextLTW01-Medium/avenir-next-lt-w01-medium.eot?#iefix") format("eot"),
		url("../fonts/AvenirNextLTW01-Medium/avenir-next-lt-w01-medium.woff2") format("woff2"),
		url("../fonts/AvenirNextLTW01-Medium/avenir-next-lt-w01-medium.woff") format("woff"),
		url("../fonts/AvenirNextLTW01-Medium/avenir-next-lt-w01-medium.ttf") format("truetype"),
		url("../fonts/AvenirNextLTW01-Medium/avenir-next-lt-w01-medium.svg#avenir-next-lt-w01-medium") format("svg");
}
@font-face{
	font-family:"AvenirNextLTW01-MediumItalic";
	src:url("../fonts/AvenirNextLTW01-MediumItalic/avenir-next-lt-w01-medium-italic.eot?#iefix");
	src:url("../fonts/AvenirNextLTW01-MediumItalic/avenir-next-lt-w01-medium-italic.eot?#iefix") format("eot"),
		url("../fonts/AvenirNextLTW01-MediumItalic/avenir-next-lt-w01-medium-italic.woff2") format("woff2"),
		url("../fonts/AvenirNextLTW01-MediumItalic/avenir-next-lt-w01-medium-italic.woff") format("woff"),
		url("../fonts/AvenirNextLTW01-MediumItalic/avenir-next-lt-w01-medium-italic.ttf") format("truetype"),
		url("../fonts/AvenirNextLTW01-MediumItalic/avenir-next-lt-w01-medium-italic.svg#avenir-next-lt-w01-medium-italic") format("svg");
}
@font-face{
	font-family:"DINCondensedW01-Normal";
	src:url("../fonts/DINCondensedW01-Normal/din-condensed-w01-normal.eot?#iefix");
	src:url("../fonts/DINCondensedW01-Normal/din-condensed-w01-normal.eot?#iefix") format("eot"),
		url("../fonts/DINCondensedW01-Normal/din-condensed-w01-normal.woff2") format("woff2"),
		url("../fonts/DINCondensedW01-Normal/din-condensed-w01-normal.woff") format("woff"),
		url("../fonts/DINCondensedW01-Normal/din-condensed-w01-normal.ttf") format("truetype"),
		url("../fonts/DINCondensedW01-Normal/din-condensed-w01-normal.svg#din-condensed-w01-normal") format("svg");
}
@font-face{
	font-family: 'FontAwesome';
	src:url('../fonts/FontAwesome/fontawesome-webfont.eot?v=4.4.0');
	src:url('../fonts/FontAwesome/fontawesome-webfont.eot?#iefix&v=4.4.0') format('embedded-opentype'),
  		url('../fonts/FontAwesome/fontawesome-webfont.woff2?v=4.4.0') format('woff2'),
  		url('../fonts/FontAwesome/fontawesome-webfont.woff?v=4.4.0') format('woff'),
  		url('../fonts/FontAwesome/fontawesome-webfont.ttf?v=4.4.0') format('truetype'),
  		url('../fonts/FontAwesome/fontawesome-webfont.svg?v=4.4.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@charset "UTF-8";

 // SASS: Global Elements
@import "_fonts.scss";

// SASS: Fonts
$font-heading: DINCondensedW01-Normal,Helvetica,Arial,sans-serif;
$font-light: AvenirNextW01-Light,Helvetica,Arial,sans-serif;
$font-regular: AvenirNextLTW01-Regular,Helvetica,Arial,sans-serif;
$font-bold: AvenirNextLTW01-Medium,Helvetica,Arial,sans-serif;

// SASS: Colors
$color-blue: #00a9c5;
$color-green: #a7ab2d;
$color-gray: #4c4d4f;

* {-webkit-box-sizing:border-box; -moz-box-sizing:border-box;  box-sizing:border-box; -webkit-font-smoothing:antialiased; -moz-osx-font-smoothing:grayscale;}
html {min-height:100%; position:relative;}
body {min-height:100%; overflow-x:hidden; font-family:serif,Helvetica,Arial,sans-serif;}
.container {position:relative; padding:0px; margin:0 auto; box-sizing:border-box;}
a:active, a:focus {outline:none; text-decoration:none;}

// SASS: Responsive Media Queries
@mixin desktop {
	$font-size: 18px !global;
	$line-height: 22px !global;
	$height-navbar:70px !global;
	$height-headline:70px !global;
	$height-button:50px !global;
	@media (min-width:1280px){
		@content;
	}
}
@mixin tablet {
	$font-size:16px !global;
	$line-height: 20px !global;
	$height-navbar:50px !global;
	$height-headline:50px !global;
	$height-button:40px !global;
	@media (min-width:768px) and (max-width:1279px){
		@content;
	}
}
@mixin mobile {
	$font-size:14px !global;
	$line-height: 18px !global;
	$height-navbar:30px !global;
	$height-headline:30px !global;
	$height-button:30px !global;
	@media (max-width:767px){
		@content;
	}
}

// SASS: Rounded Corner
@mixin rounded($radius:2px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

// SASS: FontAwesome
@mixin fa-icon($icon) {
  display: inline-block;
  font-family: FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content:$icon;
}


/* Container Widths */
@include desktop {
	.container {width:1262px!important;}
}
@include tablet {
	.container {width:750px!important;}
}
@include mobile {
	.container {width:100%!important; overflow-x:hidden;}
}

/* Header */
header {
	.top {position:relative; text-align:center;
		.bars {display:none;}
		.tagline {text-transform:uppercase; font-family:$font-light; color:$color-gray;
			span {display:inline-block; margin:0 6px;}
		}
	}
	nav {background-color:$color-blue; font-family:$font-bold; text-transform:uppercase;
		a {color:#fff; text-decoration:none;}
		a:hover,
		a.active {color:$color-gray;}
	}

	@include desktop {
		.top {
			a img {width:280px; margin-top:10px;}
			.tagline {font-size:22px; line-height:22px; margin:10px 0px;}
		}
		nav {text-align:center; height:$height-navbar; margin-bottom:30px;
			a {line-height:$height-navbar; font-size:18px; margin:0 15px;}
		}
	}

	@include tablet {
		.top {
			a img {width:240px; margin-top:10px;}
			.tagline {font-size:18px; line-height:18px; margin:10px 0px;}
		}
		nav {text-align:center; height:$height-navbar; margin-bottom:20px;
			a {line-height:$height-navbar; font-size:16px; margin:0 15px;}
		}
	}
	@include mobile {
		.top {
			.bars {display:block; position:absolute; right:15px; top:10px; cursor:pointer;
				&:before {@include fa-icon('\f039'); color:$color-gray; font-size:30px; transition:all .25s ease-in-out;}
				&.open:before {color:#878787; transform:rotate(90deg);}
			}
			a img {width:140px; margin-top:10px;}
			.tagline {font-size:14px; line-height:16px; margin:10px 0px;}
		}
		nav {padding-top:5px; height:5px; overflow:hidden;
			a {display:block; line-height:30px; font-size:16px; width:100%; padding-left:10px; border-bottom:1px solid #02778b;}
			a:hover,
			a.active {color:#fff;}
		}
	}
}


/* Banner */
.banner {background-repeat:no-repeat; background-size:cover; background-position:center center;

	@include desktop {
		height:400px;
	}
	@include tablet {
		height:325px; background-size:auto 100%;
	}
	@include mobile {
		height:200px; background-size:auto 100%;
	}
}


/* Headline */
.headline {
	h1 {color:#ffffff; font-family:$font-heading; text-transform:uppercase; text-align:center; vertical-align:text-bottom;}

	@include desktop {margin: 30px auto;
		h1 {font-size:40px; line-height:$height-headline;}
	}
	@include tablet {margin: 20px auto;
		h1 {font-size:30px; line-height:$height-headline;}
	}
	@include mobile {margin: 10px auto;
		h1 {font-size:24px; line-height:24px; padding:5px 15px; }
	}
}


/* Body Copy */
main {
	font-family:$font-regular; color:$color-gray;

	@include desktop {
		p {font-size:$font-size; line-height:$line-height;}
	}
	@include tablet {
		p {font-size:$font-size; line-height:$line-height;}
	}
	@include mobile {
		.container {padding:15px;}
		p {font-size:$font-size; line-height:$line-height;}
	}
}


/* Footer */
footer {
	position:relative; border-top:2px solid $color-gray; text-align:center;
	.copyright {
		p {font-family:$font-bold; color:$color-gray; text-transform:uppercase;}
	}
	nav.links {display:flex; flex-direction:row; flex-wrap:nowrap; align-items:stretch; justify-content:center;
		a,button#ot-sdk-btn {font-family:$font-bold; color:$color-gray; text-transform:uppercase; text-decoration:none;
			&:hover {text-decoration:underline;}
		}
		button#ot-sdk-btn {border:none; background:transparent; padding:0px;
			&:hover {background:transparent;color:$color-gray;}
		}
	}

	@include desktop {padding:20px 0;
		.copyright {margin-bottom:5px;
			p {font-size:16px; line-height:16px; }
		}
		nav.links {
			a,button#ot-sdk-btn {font-size:16px; line-height:16px; padding:0 10px;}
			a {border-right:1px solid $color-gray;}
		}
	}
	@include tablet {padding:15px 0;
		.copyright {margin-bottom:5px;
			p {font-size:14px; line-height:14px;}
		}
		nav.links {
			a,button#ot-sdk-btn {font-size:14px; line-height:14px; padding:0 10px;}
			a {border-right:1px solid $color-gray;}
		}
	}
	@include mobile {padding:15px 0;
		.container {overflow-x:visible;}
		.copyright {margin-bottom:5px;
			p {font-size:12px; line-height:12px;}
		}
		nav.links {flex-direction:column;
			a,button#ot-sdk-btn {font-size:12px; line-height:12px; margin-bottom:5px;}
		}
	}
}


/* Buttons */
.button {
	position:relative; display:block; border:2px solid $color-gray; color:$color-green; font-family:$font-heading; text-align:center; text-transform:uppercase; text-decoration:none; @include rounded(); margin:0 auto;
	&:hover {background-color:$color-green; color:#ffffff;}
	@include desktop {
		width:200px; height:$height-button; line-height:$height-button - 4px; margin:50px auto 120px; font-size:34px;
	}
	@include tablet {
		width:180px; height:$height-button; line-height:$height-button - 4px; margin:40px auto 80px; font-size:32px;
	}
	@include mobile {
		width:120px; height:$height-button; line-height:$height-button - 4px; margin:20px auto 30px; font-size:22px;
	}
}


/* Page: Home */
#home {
	.headline {background-color:$color-green;}
	main {text-align:center;}

	@include desktop {
		.banner {background-image:url('../images/home-1280-bg.jpg');}
		main p {margin:0px 100px 15px;}
	}
	@include tablet {
		.banner {background-image:url('../images/home-768-bg.jpg');}
		main p {margin:0px 20px 10px;}
	}
	@include mobile {
		.banner {background-image:url('../images/home-480-bg.jpg');}
		main p {margin-bottom:15px;}
	}
}


/* Page: What We Do */
#what {
	.headline {background-color:$color-gray;}
	p.top {border-bottom:1px solid $color-gray; padding-bottom:20px;}
	main .container {text-align:center;
		h2 {color:$color-green;}
		.left, .middle, .right {display:inline-block; vertical-align:top; font-family:$font-heading; color:$color-gray; text-transform:uppercase;}
	}

	@include desktop {
		.banner {background-image:url('../images/what-1280-bg.jpg');}
		main .container {padding:0 50px;
			h2 {font-size:34px; line-height:30px; min-height:80px;}
			li {font-size:24px; line-height:20px; margin-bottom:15px;}
			p.top {margin-bottom:30px;}
			.left, .middle, .right {width:30%; padding:0 40px;}
		}
	}
	@include tablet {
		.banner {background-image:url('../images/what-768-bg.jpg');}
		main .container {
			h2 {font-size:30px; line-height:26px; min-height:70px;}
			li {font-size:20px; line-height:18px; margin-bottom:15px;}
			p.top {margin-bottom:20px;}
			.left, .middle, .right {width:30%; padding:0 10px;}
		}
	}
	@include mobile {
		.banner {background-image:url('../images/what-480-bg.jpg');}
		main .container {
			h2 {font-size:24px; line-height:24px; margin-bottom:10px;}
			li {font-size:16px; line-height:16px; margin-bottom:5px;}
			ul {margin-bottom:20px;}
			p.top {margin-bottom:10px; padding-bottom:10px;}
			.left, .middle, .right {width:100%;}
		}
	}
}


/* Page: Our Clients */
#clients {
	.headline {background-color:$color-blue;}
	main {text-align:center;
		p.top {border-bottom:1px solid $color-gray; padding-bottom:20px;}
		ul.logos {display:block; margin:0 auto;
			li {display:inline-block;}
		}
		p.quote {margin-bottom:20px;
			span {display:block; font-weight:bold;}
		}
	}

	@include desktop {
		.banner {background-image:url('../images/clients-1280-bg.jpg');}
		main .container {padding:0 50px;}
		ul.logos {margin-bottom:20px!important;
			li {margin:0 20px;}
			img {width:150px; height:150px;}
		}
		p.quote {padding:0 70px;}
	}
	@include tablet {
		.banner {background-image:url('../images/clients-768-bg.jpg');}
		ul.logos {margin-bottom:20px!important;
			li {margin:0 10px;}
			img {width:125px; height:125px;}
		}
	}
	@include mobile {
		.banner {background-image:url('../images/clients-480-bg.jpg');}
		ul.logos {margin-bottom:10px!important;
			li {margin:0 6px;}
			img {width:75px; height:75px;}
		}
	}
}

/* Global: reCAPTCHA */
.grecaptcha-badge {display:none!important;}

/* Page: Contact Us */
#contact {
	.headline {background-color:$color-green;}
	main p.top {text-align:center; margin:0 auto;}

	form {position:relative; display:flex; flex-flow:row wrap; align-items:stretch; margin:20px auto;
		.bottom {text-align:center;
			button {background:none; cursor:pointer;}
			button:hover {background-color:$color-green;}
		}
		fieldset {display:block;
			.field {display:block; position:relative;}
			span, input, textarea {position:absolute; top:0px; left:0px; right:0px;}
			span {font-family:$font-heading; color:$color-green; text-transform:uppercase; z-index:1;}
			input, textarea {width:100%; border:1px solid #bbbdc0; color:$color-gray; @include rounded(); font-family:$font-regular; -webkit-appearance:none; background:transparent; z-index:2;}
			label {display:block; color:red;
				&.error {margin-top:5px;}
			}
		}
		.captcha {padding-top:10px;
			p a {color:#00a9c5;}
		}
		textarea:focus, input:focus {
			outline: none;
			border-color:$color-green;
	  }
	}

	@include desktop {
		.banner {background-image:url('../images/contact-1280-bg.jpg');}

		main p.top {width:1000px;}
		form {width:1100px;
			span {font-size:34px;}
			.left {width:30%;
				fieldset {margin-bottom:10px;
					.field, input {height:50px;}
					span {top:8px; left:10px;}
					input {font-size:18px;}
					input#input_1 {padding-left:85px;}
					input#input_2 {padding-left:90px;}
					input#input_3 {padding-left:130px;}
					input#input_4 {padding-left:95px;}
				}
			}
			.right {width:70%; padding-left:20px;
				fieldset {
					.field, textarea {height:230px;}
					span {top:8px; left:10px;}
					textarea {font-size:16px; padding:40px 10px 10px;}
					p {font-size:16px; line-height:18px;}
				}
			}
			.bottom {width:100%; padding-top:20px;}
		}
	}
	@include tablet {
		.banner {background-image:url('../images/contact-768-bg.jpg');}

		main p.top {width:650px;}
		form {
			span {font-size:28px;}
			.left {width:40%;
				fieldset {margin-bottom:15px;
					.field, input {height:40px;}
					span {top:6px; left:10px;}
					input {font-size:16px;}
					input#input_1 {padding-left:72px;}
					input#input_2 {padding-left:75px;}
					input#input_3 {padding-left:108px;}
					input#input_4 {padding-left:80px;}
				}
			}
			.right {width:60%; padding-left:20px;
				fieldset {
					.field, textarea {height:210px;}
					span {top:8px; left:10px;}
					textarea {font-size:14px; padding:35px 10px 10px;}
					p {font-size:16px; line-height:18px;}
				}
			}
			.bottom {width:100%; padding-top:20px;}
		}
	}
	@include mobile {
		.banner {background-image:url('../images/contact-480-bg.jpg');}

		form {
			span {font-size:22px;}
			.left, .right, .bottom {width:100%;}
			.left {
				.field, input {height:30px;}
			}
			.right {
				.field, textarea {height:210px;}
			}
			fieldset {margin-bottom:5px;
				span {top:4px; left:5px;}
				input {font-size:16px;}
				input#input_1 {padding-left:55px;}
				input#input_2 {padding-left:60px;}
				input#input_3 {padding-left:85px;}
				input#input_4 {padding-left:62px;}
				textarea {font-size:16px; padding:25px 5px 5px;}
				p {font-size:14px; line-height:16px;}
			}

			.bottom {padding-top:20px;}
		}

	}
}


/* Page: Privacy Policy */
#privacy{
	.headline {background-color:$color-green;}
	main {
		h2 {color:$color-blue; font-weight:bold; margin-bottom:5px;}
		p a {color:$color-blue;}
	}

	@include desktop {
		main {
			h2 {font-size:18px;}
			p {margin-bottom:20px;}
		}
		footer {margin-top:120px;}
	}

	@include tablet {
		main {
			h2 {font-size:16px;}
			p {margin-bottom:15px;}
		}
		footer {margin-top:80px;}
	}

	@include mobile {
		main {
			h2 {font-size:16px;}
			p {margin-bottom:15px;}
		}
		footer {margin-top:30px;}
	}

}


/* Font Line-height fix on MAC: requires jquery-trmix.min.js */
html.tr-coretext{
	@include desktop {
		header nav {height:$height-navbar - 1px; padding-top:1px;}
		.headline h1 {height:$height-headline - 3px; padding-top:3px;}
		a.button {height:$height-button - 2px; padding-top:2px;}
		label span {top:12px!important;}
	}
	@include tablet {
		header nav {height:$height-navbar - 1px; padding-top:1px;}
		.headline h1 {height:$height-headline - 3px; padding-top:3px;}
		a.button {height:$height-button - 2px; padding-top:2px;}
		label span {top:9px!important;}
	}
	@include mobile {
		.headline h1 {padding-top:4px;}
		a.button {height:$height-button - 2px; padding-top:2px;}
		label span {top:7px!important;}
	}
}